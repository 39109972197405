@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.playfair-display{
  font-family: Playfair Display;
}
.monstrrate {
  font-family: Montserrat;
}
.poppins{
  font-family: poppins;
}

.lato {
  font-family: Lato;
}

.list-item-decoration {
  /* Add dotted decoration to list items */
  list-style-type: square;
  list-style-position: inside;
}
 /* Hide scrollbar for Chrome, Safari and Opera */
.right-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.right-content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}